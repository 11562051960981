import React from 'react'

export const getAnaliticsVariables = () => {
  const googleTagManagerId = process.env.GATSBY_GOOGLE_GTM || null
  const isTagManagerEnabled = process.env.GATSBY_GOOGLE_GTM_ENABLED || false
  const isTagManagerConfigured = isTagManagerEnabled && googleTagManagerId

  return {
    googleTagManagerId,
    isTagManagerConfigured,
  }
}

const LoadGTMIframe = () => {
  const { googleTagManagerId, isTagManagerConfigured } = getAnaliticsVariables()

  if (isTagManagerConfigured) {
    return (
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
          title={`gtm${googleTagManagerId}`}
          className="optanon-category-C0002"
        ></iframe>
      </noscript>
    )
  }

  return null
}

export default LoadGTMIframe
